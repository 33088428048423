import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Routine Success",
  "date": "2018-01-02",
  "promo": "grids",
  "description": "Designing a custom routine to crush your goals",
  "pic": "/img/routines.jpg",
  "color": "#00a476"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This time of year can bring a lot of hope and encouragement, with fresh resolutions and clear ambitions. But it can also bring discouragement when you realize that you felt this same way last year but that excitement didn't translate into lasting change or meaningful results. Your resolutions fizzled out, again.`}</p>
    <p>{`I've been thinking a lot about what worked for me in 2017, and what didn't. I basically had two goals: start my own business and get in shape. I made incredible progress on the entrepreneur life: I quit my job, created Mastery Games for `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox`}</a>{` and for `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid`}</a>{`, wrote tons of `}<a parentName="p" {...{
        "href": "https://gedd.ski"
      }}>{`content`}</a>{`, and grew my audience to over 11,000 UI devs. But I didn't do jack for my health. I'm still 30lbs overweight and my sleep schedule is terrible.`}</p>
    <p>{`What was the difference between my successful goal and my failure? I had them both hung up on my wall where I'd be reminded of them daily. They were equally high priority in my mind. What made me crush one goal and neglect the other?`}</p>
    <h2>{`Goal without a Routine`}</h2>
    <p>{`I realize now that there was only one small but important difference: I had `}<strong parentName="p">{`designed a custom routine`}</strong>{` for accomplishing my entrepreneur goal. I had set aside time `}<em parentName="p">{`every single day`}</em>{` to work towards it. My "get healthy" goal remained wishful thinking and a source of constant guilt. But the biz stuff really took off as I applied constant, scheduled effort in the form of a daily routine.`}</p>
    <p>{`My takeaway from this is that `}<strong parentName="p">{`a goal without a routine is worthless`}</strong>{`. Things worth doing take work, a lot of it. The best way to make sure you're giving your goals the steady effort they require is to design a routine around them.`}</p>
    <h2>{`Routine without a Goal`}</h2>
    <p>{`I've also learned that routines need to be anchored to a specific goal in order to carry enough meaning. A routine on its own is a rather empty thing, and can be very difficult to stick to if its not tied to an outcome you truly want. I've written about the importance of having a `}<strong parentName="p">{`guiding star`}</strong>{` in `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/overcoming-fear-and-doing-your-own-thing/"
      }}>{`Overcoming Fear and Doing Your Own Thing`}</a>{`. Pick a huge goal that will make a real difference in your life when you achieve it. For me this year I have two guiding stars: lose 30lbs and earn more income than I did at my "real job". These guiding stars give me context, meaning, and direction. I've designed a custom daily routine for sailing full mast towards them.`}</p>
    <h2>{`Lag Measures vs Lead Measures`}</h2>
    <p>{`Sean Covey in `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Disciplines-Execution-Achieving-Wildly-Important/dp/1491517751"
      }}>{`The 4 Disciples of Execution`}</a>{` makes a distinction between "lag measures" and "lead measures". Lag measures are `}<em parentName="p">{`results`}</em>{`, outcomes/rewards that come to us after we put in the hard work. For example losing weight, hitting a savings target, or mastering a new skill. Lag measures are important to set our sights on. They're the guiding stars we sail towards. "I'm going to become an amazing frontend developer - one who can build anything I can imagine" is a perfect lag measure/goal. To get there though you need lead measures.`}</p>
    <p>{`A lead measure is something that is indicative of the lag measure. An action that if done consistently will lead to the end result you're after. Saving \\$300 per paycheck `}<em parentName="p">{`leads`}</em>{` to hitting savings goals. Going to the gym every weekday `}<em parentName="p">{`leads`}</em>{` to getting in shape. `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Practicing building UIs`}</a>{` `}<em parentName="p">{`leads`}</em>{` to skilled proficiency. You have direct control over lead measures. The key is to pick the right lead measures for your goal and then build them into your routine.`}</p>
    <h2>{`Routine, Not Habit`}</h2>
    <p>{`I used to make goals like "develop a drawing habit". Not anymore. Habits are too vague and unpredictable. Does it take 60 days to make a habit? Six months? What does it take to sustain a habit? Who knows. Habits are more of an outcome that may or may not kick in at some point and make things easier. Not something you should bank on or even worry about now. A routine however is directly in your control. You can start a new routine tonight. You can choose to do your routine every single day. Eventually the habit fairy will come along and make doing your routine feel natural and require very little effort. In the meantime though, just go rock your routine.`}</p>
    <h2>{`The Hardest Part is the Start`}</h2>
    <p>{`My new routine starts at 10:00PM every night (a successful morning routine starts the night before). When 10PM rolls around I feel very little desire to shut my brain/computer down and start getting ready for bed. I remind myself that this is what it takes to get healthy and self-reliant, but going to brush my teeth sounds so lame in the moment compared to playing Overwatch. But as soon as I force myself to go do that first item on the routine, the `}<strong parentName="p">{`motivation returns`}</strong>{` and the rest of the routine is a piece of cake. Getting started is the hardest part. Because of this phenomenon I've changed my routine and no joke made petting my dog Sam the very first item in my nightly routine. Pick something easy and enjoyable as your first item to jumpstart your routine, and breeze past the hardest part of simply getting started.`}</p>
    <h2>{`This is Your Year`}</h2>
    <p>{`Pick one or two guiding stars (no more). Something you `}<strong parentName="p">{`really`}</strong>{` want to make happen this year. Something that would make a massive and awesome difference for you. Next decide on a couple of lead measures - actions that if done consistently will lead you to the outcome you want. Now build those actions into a daily routine. Stick to your routine - feel free to adjust/tweak it as you learn. Soon your routine will become habit and get easier, and eventually `}<strong parentName="p">{`you will arrive`}</strong>{` at your star.`}</p>
    <p>{`I'll let you know how my journey goes, and I'd love to hear about yours as well. Feel free to hit me up over email or Twitter DM anytime. Best of luck, this is your year!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      